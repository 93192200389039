import React from 'react';
import Toolbar from './components/Toolbar';
import Document from './components/Document';
import PropTypes from 'prop-types';
import PaginationWithClick from '../common/components/PaginationWithClick';
import Config from '../config';
import Ajax from '../common/ajax';
import Waiting from '../common/containers/Waiting';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';
import Panel from '../common/containers/Panel';
import Jumbotron from '../common/containers/Jumbotron';
import { withNamespaces } from 'react-i18next';
import AccountDeleteInfo from '../account/AccountDeleteInfo';
import EmailConfirmation from '../account/EmailConfirmation';
import Videos from '../help/Videos';

class DocumentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      documents: [],
      pagination: {},
      currentUrl: '',
    };

    this.fields = [
      'id',
      'title',
      'last_modification_date',
      'template_meta__name',
      'template_meta__type',
      'reference_count',
      'page_count',
      'authors_comma_separated',
      'total_revisions',
      'figure_count',
      'type',
      'is_presentation',
    ];

    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleRevertTrashClick = this.handleRevertTrashClick.bind(this);
    this.handleRemovePermanentlyClick = this.handleRemovePermanentlyClick.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  componentDidUpdate(prevProps) {
    const { category, type } = this.props;
    if (prevProps.type !== type || prevProps.category !== category) {
      const url = `${Config.apiHost}documents/list_${category}/?template_type=${type}`;
      this.fetchDocuments(url);
    }
  }

  fetchDocuments(
    url = `${Config.apiHost}documents/list_${this.props.category}/?ordering=-last_modification_date,title&template_type=${this.props.type}`,
  ) {
    this.setState((state) => ({
      ...state,
      processing: true,
      currentUrl: url,
    }));

    const fields = [...this.fields];

    if (this.props.type === 'presentations') {
      fields.push('cover_thumbnail');
    }

    Ajax.get(url, fields).done((pagination) => {
      this.setState((state) => ({
        ...state,
        processing: false,
        pagination: pagination,
        documents: pagination.results.map((document) => (
          <Document
            key={document.id}
            document={document}
            handleRemoveClick={this.handleRemoveClick}
            handleRevertTrashClick={this.handleRevertTrashClick}
            handleRemovePermanentlyClick={this.handleRemovePermanentlyClick}
            isInTrash={this.props.category === 'trash'}
            onCopy={this.onCopy}
          />
        )),
      }));
    });
  }

  handlePaginationClick(url) {
    this.fetchDocuments(decodeURIComponent(url));
  }

  handleSearch(searchTerm) {
    this.setState((state) => ({ ...state, searchTerm: searchTerm }));
    const url = `${Config.apiHost}documents/list_${this.props.category}/?search=${searchTerm}&template_type=${this.props.type}`;
    this.fetchDocuments(url);
  }

  handleSort(sortField, ordering) {
    if (ordering === 'desc') {
      sortField = '-' + sortField;
    }

    let url = `${Config.apiHost}documents/list_${this.props.category}/?ordering=${sortField}&template_type=${this.props.type}`;

    if (this.state.searchTerm) {
      url += `&search=${this.state.searchTerm}`;
    }
    this.fetchDocuments(url);
  }

  handleRemoveClick(documentId) {
    const url = `${Config.apiHost}documents/${documentId}/`;
    Ajax.delete_(url).done((resp) => {
      this.fetchDocuments();
    });
  }

  handleRemovePermanentlyClick(documentId) {
    const { i18n } = this.props;
    const confirmed = confirm(i18n.t('Confirma a exclusão do documento? Não poderá desfazer.'));
    if (confirmed) {
      const url = `${Config.apiHost}documents/${documentId}/remove_permanently/`;
      Ajax.delete_(url).done((resp) => {
        this.fetchDocuments();
      });
    }
    return confirmed;
  }

  handleRevertTrashClick(documentId) {
    const url = `${Config.apiHost}documents/${documentId}/revert_from_trash/`;
    Ajax.post(url).done((resp) => {
      this.fetchDocuments();
    });
  }

  onCopy(newDocumentId) {
    this.fetchDocuments(this.state.currentUrl);
  }

  render() {
    const { i18n, type } = this.props;
    const isPresentations = type == 'presentations';

    const videos = (
      <>
        <div id="accordion1" className="panel-group accordion" style={{ marginBottom: '20px' }}>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion1"
                  href="#collapseOne"
                  aria-expanded="false"
                  className="collapsed"
                >
                  <i className="icon mdi mdi-chevron-down" /> Vídeos tutoriais &mdash; Assista a vídeos rápidos de como
                  editar seu documento.
                </a>
              </h4>
            </div>
            <div id="collapseOne" className="panel-collapse collapse " aria-expanded="false">
              <div className="panel-body">
                <Videos />
              </div>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <>
        <Row>
          <Col md={10} mdOffset={1}>
            <AccountDeleteInfo />
            <EmailConfirmation />
          </Col>
        </Row>
        <Row>
          <Col md={10} mdOffset={1}>
            <Toolbar handleSearch={this.handleSearch} handleSort={this.handleSort} type={this.props.type} />
          </Col>
        </Row>
        <Row>
          <Col md={10} mdOffset={1}>
            <Waiting isProcessing={this.state.processing} />

            {this.props.category === 'my' && videos}

            {this.state.documents}

            {this.state.pagination.count === 0 && this.props.category !== 'my' && (
              <>
                {isPresentations ? (
                  <h2>{i18n.t('Nenhuma apresentação encontrada')}</h2>
                ) : (
                  <h2>{i18n.t('Nenhum documento encontrado')}</h2>
                )}
              </>
            )}

            {this.state.pagination.count === 0 && this.props.category === 'my' && !this.state.processing && (
              <>
                <Panel>
                  <Jumbotron style={{ backgroundColor: 'white' }}>
                    <h2>
                      {isPresentations
                        ? i18n.t('Veja como é fácil formatar sua apresentação')
                        : i18n.t('Veja como é fácil formatar seu documento')}
                    </h2>
                    <hr />
                    <br />
                    <ul className="user-timeline user-timeline-compact">
                      <li className="latest">
                        <div className="user-timeline-title">
                          <b>{i18n.t('Passo')} 1</b> -{' '}
                          {isPresentations
                            ? i18n.t('Clique em "Nova apresentação"')
                            : i18n.t('Clique em "Novo documento"')}
                        </div>
                      </li>
                      <li className="latest">
                        <div className="user-timeline-title">
                          <b>{i18n.t('Passo')} 2</b> - {i18n.t('Selecione um dos modelos de formatação disponíveis')}
                        </div>
                      </li>
                      <li className="latest">
                        <div className="user-timeline-title">
                          <b>{i18n.t('Passo')} 3</b> - {i18n.t('Escreva seu texto ou importe de um arquivo MS Word')}
                        </div>
                      </li>
                      <li className="latest">
                        <div className="user-timeline-title">
                          <b>{i18n.t('Passo')} 4</b> -{' '}
                          {isPresentations
                            ? i18n.t('Visualize e edite sua apresentação')
                            : i18n.t('Visualize e edite seu documento')}
                        </div>
                      </li>
                      <li className="latest">
                        <div className="user-timeline-title">
                          <b>{i18n.t('Passo')} 5</b> -{' '}
                          {isPresentations
                            ? i18n.t('Faça download da apresentação formatada')
                            : i18n.t('Faça download do documento formatado')}
                        </div>
                      </li>
                    </ul>
                    <hr />
                    <h4 style={{ maxWidth: '600px' }}>
                      {i18n.t(
                        'Caso precise de mais auxílio, no menu "Ajuda" você encontra uma lista de vídeos que ensinam todas as funcionalidades do FastFormat.',
                      )}
                    </h4>
                  </Jumbotron>
                </Panel>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={10} mdOffset={1}>
            <PaginationWithClick handleClick={this.handlePaginationClick} pagination={this.state.pagination} />
          </Col>
        </Row>
      </>
    );
  }
}

DocumentList.propTypes = {
  category: PropTypes.oneOf(['my', 'shared', 'trash']),
  type: PropTypes.oneOf(['documents', 'presentations']),
};

DocumentList.defaultProps = {
  category: 'my',
  type: 'documents',
};

export default withNamespaces()(DocumentList);
